import React from "react";
import "./Testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards on larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1, // Show 1 card on smaller screens (e.g., phones)
        },
      },
    ],
  };

  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Testimonials</h2>
          <p>What Are people saying About us</p>
        </div>

        <div
          className="testimonials-slider swiper"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="swiper-wrapper">
            <Slider {...settings}>
              <div className="testimonial-card">
                <div className="testimonial-item">
                  <div className="stars">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                  </div>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Working with June Geo Solution LTD has been an absolute
                    pleaure. Their team's expertise in collecting and managing
                    geospatial data has been invaluable to our operations,
                    allowing us to make informed decisions based on reliable
                    data.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <div className="profile mt-auto">
                    <img
                      src="../sotik.png"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3> Sotik tea company</h3>
                    <h4>Ceo &amp; Founder</h4>
                  </div>
                </div>
              </div>
              <div className="testimonial-card">
                <div className="testimonial-item">
                  <div className="stars">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                  </div>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    June Geo Solution LTD mapping and visualization services
                    have provided us with a new level of clarity into our
                    operations. Their team's ability to create visual
                    representations of our geospatial data has allowed us to
                    make informed decisions
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <div className="profile mt-auto">
                    <img
                      src="../sisibo.jpeg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sisibo tea factory</h3>
                    <h4>Ceo &amp; Founder</h4>
                  </div>
                </div>
              </div>
              <div className="testimonial-card">
                <div className="testimonial-item">
                  <div className="stars">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                  </div>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    The experience of working with June Geo Solution LTD has
                    been wonderful. Our operations have benefited greatly from
                    their team's skill in gathering and handling geospatial
                    data, which has allowed us to make defensible judgments.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <div className="profile mt-auto">
                    <img
                      src="chemusian.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3> Chemusian tea estates</h3>
                    <h4>Ceo &amp; Founder</h4>
                  </div>
                </div>
              </div>
              <div className="testimonial-card">
                <div className="testimonial-item">
                  <div className="stars">
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                    <i className="bi bi-star-fill"></i>
                  </div>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Working with June Geo Solution LTD has been an absolute
                    pleasure. Their team's expertise in collecting and managing
                    geospatial data has been invaluable to our operations,
                    allowing us to make informed decisions based on reliable
                    data.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <div className="profile mt-auto">
                    <img
                      src="../client5.png"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3> Sotik tea company</h3>
                    <h4>Ceo &amp; Founder</h4>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
