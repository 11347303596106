import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [activeLink, setActiveLink] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleLinkClick = (index) => {
    setActiveLink(index);
    setIsNavOpen(false);
  };

  const handleToggleClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header
      id="header"
      className={`fixed-top d-flex align-items-center ${
        isNavOpen ? "mobile-menu-open" : ""
      }`}
    >
      <div className="container d-flex align-items-center justify-content-between">
        <div className="navbar-brand">
          <a href="index.html">
            <img
              src="../logo.png"
              alt=""
              className="img-fluid"
              style={{
                maxWidth: "50px",
                height: "auto",
                borderRadius: "50%",
                padding: "05px",
              }}
            />
          </a>
          <a href="#">June Geo Solution </a>
        </div>

        <nav
          id="navbar"
          className={`navbar ${isNavOpen ? "navbar-mobile" : ""}`}
        >
          <ul>
            <li>
              <ScrollLink
                to="/"
                className={
                  activeLink === 0 ? "navbar-link active" : "navbar-link"
                }
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => handleLinkClick(0)}
              >
                Home
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="about"
                className={
                  activeLink === 1 ? "navbar-link active" : "navbar-link"
                }
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => handleLinkClick(1)}
              >
                About
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="team"
                className={
                  activeLink === 2 ? "navbar-link active" : "navbar-link"
                }
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => handleLinkClick(2)}
              >
                Team
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="service"
                className={
                  activeLink === 3 ? "navbar-link active" : "navbar-link"
                }
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => handleLinkClick(3)}
              >
                Service
              </ScrollLink>
            </li>

            <li>
              <ScrollLink
                to="portfolio"
                className={
                  activeLink === 4 ? "navbar-link active" : "navbar-link"
                }
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => handleLinkClick(4)}
              >
                Portfolio
              </ScrollLink>
            </li>

            <li>
              <ScrollLink
                to="contact"
                className={
                  activeLink === 5 ? "navbar-link active" : "navbar-link"
                }
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => handleLinkClick(5)}
              >
                Contact
              </ScrollLink>
            </li>
          </ul>
        </nav>

        <div className="header-social-links">
          <a
            href="https://wa.me/254799300106?text=Hello i Would like to Make an Inquiry"
            className="whatsapp"
          >
            <i className="bi bi-whatsapp flex-shrink-0"></i>
          </a>
          <a
            href="mailto:junesolutionsltd@gmail.com?subject=Inquiry&body=Hello i Would like to Make an Inquiry"
            className="email"
          >
            <i className="ri-mail-send-line"></i>
          </a>
          <a href="#" className="instagram">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="#" className="linkedin">
            <i className="bi bi-linkedin"></i>
          </a>
        </div>

        <button className="mobile-nav-toggle" onClick={handleToggleClick}>
          {/* Depending on the state, toggle between 'bi-list' and 'bi-x' classes */}
          <i className={`bi ${isNavOpen ? "bi-x" : "bi-list"}`}></i>
        </button>
      </div>
    </header>
  );
}

export default Navbar;
