import React from "react";
import "./About.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Count from "./Count";
import About3 from "./About3";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>About</h2>
          <p className="about-p" data-aos="fade-" data-aos-delay="100">
            {" "}
            At June Geo Solution, we understand that every industry has unique
            requirements when it comes to geospatial data. That's why we tailor
            our solutions to meet the specific needs of various sectors,
            including agriculture, logistics, and transportation. Whether it's
            optimizing crop management, improving supply chain efficiency, or
            enhancing route planning, our solutions empower businesses to
            maximize their operational effectiveness and achieve tangible
            results.
          </p>
        </div>

        <div className="row content">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            <p className="about-p" data-aos="fade-" data-aos-delay="100">
              <div className="section-sub-title">
                <h2> Precision Redefined</h2>
              </div>
              At June Geo Solution, precision isn't a promise; it's a guarantee.
              Our advanced polygon mapping redefines accuracy in geographical
              data. Using cutting-edge technology, we meticulously map every
              polygon, offering a clear and reliable foundation for informed
              decision-making.
            </p>
            <p className="about-p" data-aos="fade-" data-aos-delay="100">
              <div className="section-sub-title">
                <h2> Seamless Integration</h2>
              </div>
              we prioritize seamless integration. Our mapping services offer
              actionable insights, seamlessly fitting into your workflow.
              Whether navigating terrains or analyzing spatial relationships,
              our solutions make exploring geographical data intuitive and
              efficient.
            </p>
            <p className="about-p" data-aos="fade-" data-aos-delay="100">
              <div className="section-sub-title">
                <h2> Unleashing cutting-edge mapping solutions at our core!</h2>
              </div>
              <p>
                let's connect. I'm here to make your digital journey seamless
                and successful.
              </p>
              <a href="#" className="btn-learn-more">
                Learn More
              </a>
            </p>
          </div>
          <div
            className="image col-xl-6 d-flex align-items-stretch justify-content-center justify-content-xl-start"
            d
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <img src="image5.png" alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <Count />
      <About3 />
    </section>
  );
};

export default About;
