import React, { useEffect } from "react";
import "./About.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About3 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards on larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000, // Adjust the duration between slides (in milliseconds)
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1, // Show 1 card on smaller screens (e.g., phones)
        },
      },
    ],
  };
  useEffect(() => {
    const AOS = require("aos");
    AOS.init();
  }, []);
  return (
    <section id="vision" className="vision">
      <div className="container">
        <div className="slides-3 swiper">
          <div className="swiper-wrapper">
            <Slider {...settings}>
              <div
                className="vision-card"
                style={{
                  backgroundImage: `url(../Blue.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  zIndex: "-1",
                  /* Additional background properties can be added here */
                }}
              >
                <div
                  className="vision-item"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="section-header">
                    <h2> Mission:</h2>
                  </div>

                  <p>
                    Our mission at June Geo Solution LTD is to provide accurate
                    and reliable geospatial data to help businesses and
                    organizations make informed decisions that positively impact
                    their operations, customer experiences, and the environment.
                  </p>
                </div>
              </div>
              <div className="vision-card">
                <div
                  className="vision-item"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="section-header">
                    <h2> Vision: </h2>
                  </div>

                  <p>
                    Our vision at June Geo Solution LTD is to be the leading
                    provider of geospatial data solutions, driving innovation
                    and progress across industries, while promoting
                    sustainability and social responsibility.
                  </p>
                </div>
              </div>

              <div className="vision-card">
                <div
                  className="vision-item"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="section-header">
                    <h2> Core value</h2>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <ul>
                        <li>Accuracy</li>
                        <li>Customer-centricity</li>
                        <li>Reliability</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <ul>
                        <li>Integrity</li>
                        <li>Environmental Responsibility</li>
                        <li>Innovation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About3;
