import React, { useEffect } from "react";
import "./Portfolio.css";

const Portfolio = () => {
  useEffect(() => {
    const AOS = require("aos");
    AOS.init();
  }, []);

  return (
    <section id="portfolio" className="portfolio section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Portfolio</h2>
          <p>
            Discover the geo-mapping prowess of our company through our
            captivating portfolio
          </p>
        </div>
        <div
          className="row row justify-content-center"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <div
            className="col-lg-9 order-1 order-lg-2 port-img"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <div
              id="carouselExampleCaptions"
              className="carousel slide "
              data-aos="fade-left"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  className="custom-carousel-btn"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  className="custom-carousel-btn"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  className="custom-carousel-btn"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src="port1.png" class="d-block w-100" alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Sisibi Cherengany Catchment Map</h5>
                    <p>
                      our team crafted the Sisibi Cherengany Catchment Map,
                      offering a precise visualization of hydrological features
                      and land use patterns for strategic watershed management
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="port2.png" class="d-block w-100" alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Chemusian Outgrowers Catchment Map</h5>
                    <p>
                      Utilizing advanced GIS techniques, our team crafted the
                      Sisibi Cherengany Catchment Map, delivering a precise
                      depiction of hydrological features and land use patterns
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="port3.png" class="d-block w-100" alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Polygons</h5>
                    <p>
                      Some representative placeholder content for the third
                      slide.
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src="port4.png" class="d-block w-100" alt="..." />
                  <div className="carousel-caption d-none d-md-block">
                    <h5>Polygons</h5>
                    <p>
                      Some representative placeholder content for the third
                      slide.
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
