import React, { useEffect } from "react";

import "./Service.css";

const Service = () => {
  useEffect(() => {
    const AOS = require("aos");
    AOS.init();
  }, []);
  return (
    <section id="service" className="services">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
          <p>
            In the realm of geospatial solutions, June Geo Solutions is pleased
            to provide a variety of services that satisfy a variety of demands.
            As a company that specializes in geospatial mapping and upholds
            Rainforest Alliance standards.
          </p>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex d">
            <div
              className="service-item position-relative"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <i className="bi bi-activity"></i>
              <h4>
                <a href="" className="stretched-link">
                  Consultation and Project Planning
                </a>
              </h4>
              <p>
                support our clients in developing and putting into practice
                mapping initiatives that adhere to Rainforest Alliance criteria.
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex">
            <div
              className="service-item position-relative"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <i className="bi bi-bounding-box-circles"></i>
              <h4>
                <a href="" className="stretched-link">
                  Polygon Mapping
                </a>
              </h4>
              <p>
                At June Geo Solutions, we create precise, comprehensive polygon
                maps based on the specifications of the project
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex">
            <div
              className="service-item position-relative"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <i className="bi bi-calendar4-week"></i>
              <h4>
                <a href="" className="stretched-link">
                  Farm Inspection
                </a>
              </h4>
              <p>
                The farm inspection services we provide meet RAA criteria. Our
                farm inspection services are made to evaluate and keep track of
                farms in order to make sure they adhere to the standards
              </p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex">
            <div
              className="service-item position-relative"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              <i className="bi bi-broadcast"></i>
              <h4>
                <a href="" className="stretched-link">
                  Data Acquisition
                </a>
              </h4>
              <p>
                We offer data collection services, such as the use of
                high-resolution satellite pictures created specifically for
                mapping and examining tea fields.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
