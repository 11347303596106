import React, { useEffect } from "react";
import "./Home.css";
import { Link } from "react-scroll";
import About from "./About";
import Service from "./Service";
import Contact from "./Contact";
import Team from "./Team";
import Testimonial from "./Testimonial";
import Portfolio from "./Portfolio";

const Home = () => {
  useEffect(() => {
    const AOS = require("aos");
    AOS.init();
  }, []);

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">Welcome to June Geo Solution Ltd</h1>
              <h2 data-aos="fade-up" data-aos-delay="400">
                Your Geospatial Partner Mapping the world, one data point at a
                time.
              </h2>
              <div data-aos="fade-up" data-aos-delay="800">
                <Link
                  to="about"
                  class="btn-get-started scrollto"
                  smooth={true}
                  duration={800}
                >
                  Get Started
                </Link>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <div
                id="carouselExampleSlidesOnly"
                className="carousel slide "
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="home2.jpg"
                      className="img-fluid animated"
                      alt=""
                    ></img>
                  </div>
                  <div className="carousel-item ">
                    <img
                      src="home5.jpg"
                      className="img-fluid animated"
                      alt=""
                    ></img>
                  </div>
                  <div className="carousel-item ">
                    <img
                      src="hom.jpg"
                      className="img-fluid animated"
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="clients" class="clients clients">
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="client1.png"
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="client2.png"
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="100"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="client3.png"
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="200"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="client5.png"
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="300"
              />
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img
                src="assets/img/clients/client-6.png"
                class="img-fluid"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="500"
              />
            </div>
          </div>
        </div>
      </section>
      <About />
      <Team />
      <Service />

      <Portfolio />
      <Testimonial />
      <Contact />
    </>
  );
};

export default Home;
